<template>
  <div class="key-facts">
    <SectionWrap nopad>
      <div class="key-facts-section">
        <div class="key-facts-section__main" data-test="key-facts-section-main">
          <div
            v-for="(item, index) in keyFactsPoints"
            :key="index"
            class="key-facts-section__main--item"
          >
            <div v-if="item.icon" class="key-facts-section__main--icon">
              <SvgIcon width="24" height="24" :name="item.icon" />
            </div>
            <div v-if="item.title" class="key-facts-section__main--title">
              {{ item.title }}
            </div>
            <div
              v-if="item.value"
              class="key-facts-section__main--value"
              v-html="item.value"
            />
            <!-- eslint-disable-line vue/no-v-html -->
            <img v-if="item.img" :src="item.img" />
          </div>
          <div
            v-if="keyFactsImages"
            class="key-facts-section__main--item key-facts-section__main--images"
          >
            <div
              v-if="keyFactsImages.title"
              class="key-facts-section__main--title"
            >
              {{ keyFactsImages.title }}
            </div>
            <div class="key-facts-section__main--value">
              <img
                v-for="(item, index) in keyFactsImages.images"
                :key="index"
                class="key-facts-section__main--img"
                :src="item.url"
                :alt="item.alt"
              />
            </div>
          </div>
          <div
            v-if="keyFactsImagesTwo"
            class="key-facts-section__main--item key-facts-section__main--images"
          >
            <div
              class="key-facts-section__main--title key-facts-section__main--placeholder"
            >
              &nbsp;
            </div>
            <div class="key-facts-section__main--value">
              <img
                v-for="(item, index) in keyFactsImagesTwo.images"
                :key="index"
                class="key-facts-section__main--img"
                :src="item.url"
                :alt="item.alt"
              />
            </div>
          </div>
        </div>
        <div class="key-facts-cta">
          <p v-if="intakeMessage" class="cta-panel-message">
            {{ intakeMessage }}
          </p>
          <CtaPanel
            :intake-message="intakeMessage"
            :data="keyFactsButtons"
            class="key-facts-cta__panel"
          />
          <div
            v-if="keyFactsButtonsAdditional.length"
            class="key-facts-buttons-additional key-facts-cta__panel"
          >
            {{ keyFactsButtonsAdditional }}
          </div>
        </div>
      </div>
    </SectionWrap>
  </div>
</template>

<script>
import { SectionWrap, SvgIcon } from '@unimelb/pattern-lib-vue';
import CtaPanel from '~/components/CtaPanel.vue';

export default {
  name: 'KeyFactsPanel',
  components: {
    SectionWrap,
    CtaPanel,
    SvgIcon,
  },
  props: {
    course: {
      type: Object,
      default: () => {},
    },
    keyFactsPoints: {
      type: Array,
      default: () => [],
    },
    keyFactsButtons: {
      type: Array,
      default: () => [],
    },
    keyFactsButtonsAdditional: {
      type: String,
      default: () => '',
    },
    keyFactsImages: {
      type: Object,
      default: () => null,
    },
    keyFactsImagesTwo: {
      type: Object,
      default: () => null,
    },
    intakeMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
};
</script>

<style lang="postcss">
.key-facts {
  background-color: var(--col-bg-alt-25);
}

.key-facts-section {
  width: 100%;
  background-color: var(--col-bg-alt-25);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding-block: var(--sp-layout);

  @media (--bp-x-desktop) {
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: min-content;
    row-gap: 1.5rem;

    @media (--bp-desktop) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    &--item {
      display: grid;
      max-width: auto;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
    }

    &--icon {
      width: max-content;
      grid-column: 1;
      grid-row: span 2;
      padding-right: 0.5rem;
      color: var(--col-bg-primary);
    }

    &--title {
      @mixin typography-uppercase;

      grid-row: 1;
      grid-column: 2;
      font-weight: var(--fw-semibold);
      font-size: calc(18 / var(--fs-base) * 1rem);
      width: 100%;
      color: var(--col-bg-primary);
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      justify-self: start;
    }

    &--value {
      font-weight: var(--fw-regular);
      font-size: var(--fs-level-6);
      justify-content: center;
      color: var(--col-grey-dark-100);
      grid-row: 2;
      grid-column: 2;
      width: auto;
      padding-right: 1.5rem;

      img {
        max-width: 12.5rem;
      }
    }

    &--title,
    &--value {
      font-size: 0.875rem;
    }

    .key-facts-section__main--images {
      .key-facts-section__main--value {
        img {
          display: block;
        }
      }
      .key-facts-section__main--placeholder {
        display: none;
        @media (--bp-x-desktop) {
          display: block;
        }
      }
    }
  }

  .cta-panel-message {
    display: block;
    color: var(--col-heritage-100);
    font-weight: var(--fw-semibold);
    padding: 0 1.5rem;
    margin-bottom: var(--sp-3);
    text-align: center;

    @media (--bp-x-desktop) {
      text-align: left;
    }
  }
}

.key-facts-cta {
  &__panel {
    align-items: center;
    padding: 0;
  }

  .key-facts-buttons-additional {
    color: var(--col-heritage-100);
    font-weight: var(--fw-semibold);
    margin-top: 1rem;

    @media screen and (max-width: 899px) {
      text-align: center;
      grid-template-columns: 1fr;
    }
  }
}
</style>
